@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import '~antd/dist/antd.css';

body{
  font-family: 'Nunito', sans-serif;
  min-height: 100vh;
}

.modal-header{
  border-bottom: none;
}

.modal-content{
  background: #FFFFFF;
  border-radius: 12px;
  .btn-close{
    // color: #C4C4C4;
    // opacity: 1;
  }
}

.item_country{
  display: flex;
  align-items: center;
  img{
    margin-right: 5px;
  }
}

.item_select{
  display: flex;
  align-items: center;
  padding: 8px 0px;
  img{
    margin-right: 5px;
    max-width: 24px;
    max-height: 24px;
  }
}

.item_country_select{
  display: flex;
  align-items: center;
  img{
    margin-right: 5px;
    max-width: 24px;
  }
}

.ant-select-dropdown{
  .ant-empty{
    .ant-empty-image, .ant-empty-description{
      display: none;
    }
  }
  .ant-select-item{
    .item_country_select{
      display: flex;
      align-items: center;
      height: 40px;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      /* identical to box height, or 111% */

      letter-spacing: 1px;

      color: #797A8A;
      img{
        margin-right: 16px;
      }
    }
    &.ant-select-item-option-active{
      background: #7879F1;
      /* Secondary/grey/strokes-for-inputs */
      
      border: 1px solid #ECF0F4;
      box-sizing: border-box;
      border-radius: 8px;
      color: white;
      .item_country_select, .item_select{
        color: white;
      }
    }
  }
}

.SpaceBetween{
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.AlignItemsCenter{
  display: flex;
  align-items: center;
  justify-content: center;
}