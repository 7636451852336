@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.style_SpinnerPage__1m7tU{width:100%;height:100%;display:flex;align-items:center;justify-content:center;min-height:100vh}
body{font-family:"Nunito",sans-serif;min-height:100vh}.modal-header{border-bottom:none}.modal-content{background:#fff;border-radius:12px}.item_country{display:flex;align-items:center}.item_country img{margin-right:5px}.item_select{display:flex;align-items:center;padding:8px 0px}.item_select img{margin-right:5px;max-width:24px;max-height:24px}.item_country_select{display:flex;align-items:center}.item_country_select img{margin-right:5px;max-width:24px}.ant-select-dropdown .ant-empty .ant-empty-image,.ant-select-dropdown .ant-empty .ant-empty-description{display:none}.ant-select-dropdown .ant-select-item .item_country_select{display:flex;align-items:center;height:40px;font-weight:600;font-size:18px;line-height:20px;letter-spacing:1px;color:#797a8a}.ant-select-dropdown .ant-select-item .item_country_select img{margin-right:16px}.ant-select-dropdown .ant-select-item.ant-select-item-option-active{background:#7879f1;border:1px solid #ecf0f4;box-sizing:border-box;border-radius:8px;color:#fff}.ant-select-dropdown .ant-select-item.ant-select-item-option-active .item_country_select,.ant-select-dropdown .ant-select-item.ant-select-item-option-active .item_select{color:#fff}.SpaceBetween{display:flex;align-items:center;justify-content:space-between}.AlignItemsCenter{display:flex;align-items:center;justify-content:center}
